import { defineStore } from 'pinia'
import { 
  getAllPermissionListApi, 
  getAllAdminListApi, 
  getAllRoleListApi,
  getLogListApi,
  getLogActionListApi 
} from '@/service/modules/role'
import { addLoading } from '@/utils/element-method'

interface roleStoreStateType {
  allPermissionList: any[],
  allAdminList: any[],
  allAdminTotal: 0,
  allRoleList: any[],
  logList: any[],
  logTotal: number,
  logActionList: any[],
  logActionTotal: number,
  roleList: any[],
  roleListTotal: 0
}
const useRoleStore = defineStore('role', {
  state: (): roleStoreStateType => ({
    allPermissionList: [], // 权限列表
    allAdminList: [], // 账号列表
    allAdminTotal: 0, // 账号总数
    allRoleList: [], // 总角色列表
    logList: [], //登录日志
    logTotal: 0, // 登录日志总数
    logActionList: [], // 操作日志列表
    logActionTotal: 0, // 操作日志总数
    roleList: [], // 角色列表
    roleListTotal: 0 // 角色总数
  }),
  actions: {
    async getAllPermissionListAction() {
      // this.allPermissionList = []
      // const loadingInstance = addLoading('.permission-tabel-data')
      try {
        let res = await getAllPermissionListApi()
        this.allPermissionList = res.data
      } catch {} finally {
        // loadingInstance.close()
      }
    },

    async getAllAdminListAction(params: any) {
      const loadingInstance = addLoading('.admin-table')
      try {
        let res = await getAllAdminListApi(params)
        this.allAdminList = res.data.data
        this.allAdminTotal = res.data.total
      } catch {} finally {
        loadingInstance.close()
      }
    },

    async getAllRoleListAction() {
      try {
        let res = await getAllRoleListApi()
        this.allRoleList = res.data.data
      } catch {}
    },

    async getRoleListAction(params: any) {
      const loadingInstance = addLoading('.role-table')
      try {
        let res= await getAllRoleListApi(params.page, params.pageSize)
        this.roleList = res.data.data
        this.roleListTotal = res.data.total
      } catch {} finally {
        loadingInstance.close()
      }
    },

    async getLogListAction(params: any) {
      const loadingInstance = addLoading('.log-table')
      try {
        let res = await getLogListApi(params)
        this.logList = res.data.data
        this.logTotal = res.data.total
      } catch {} finally {
        loadingInstance.close()
      }
    },

    async getLogAction(params: any) {
      const loadingInstance = addLoading('.action-table')
      try {
        let res = await getLogActionListApi(params)
        this.logActionList = res.data.data
        this.logActionTotal = res.data.total
      } catch {} finally {
        loadingInstance.close()
      }
    }
  }
})
export default useRoleStore