import request from '../request'
import type { accoutType, phoneType } from '../../types/loginTypes'
// 获取图片验证码
export function getCaptchaApi() {
  return request.get('/verifyCode')
}

// 账号登录
export function loginByAccoutApi(params: accoutType) {
  return request.post('/admin/login', params)
}

// 退出登录
export function getLoginOutApi() {
  return request.get('/admin/logout')
}

// 用户信息及权限
export function getUserPermissionApi() {
  return request.get('/admin/user_info')
}

// 获取验证码
export function getPhoneCodeApi(params: phoneType) {
  return request.post('/admin/send_sms', params)
}