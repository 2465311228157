import { defineStore } from 'pinia'
import { getUserPermissionApi } from '@/service/modules/login'
import { localCache } from '@/utils/cache'
import { getMenuList } from '@/utils/math'
import router from '@/router'
import { filterAsyncRoutes } from '@/utils/math'

 interface loginStoreStateType {
  userInfo: any,
  userPermissionList: any[],
  permissiones: string[],
  redirect: string
 }
const useLoginStore = defineStore('login', {
  state: (): loginStoreStateType => ({
    userInfo: localCache.getCache('userInfo') ?? {},
    userPermissionList: [],
    permissiones: [],
    redirect: ''
  }),
  actions: {
    async loginAction(userInfo: any) {
      // 登录缓存用户信息
      this.userInfo = userInfo
      localCache.setCache('userInfo',userInfo)

      // 获取用户权限
      await this.getUserPermissionAction()
      let menuRoutes = filterAsyncRoutes(this.userPermissionList)
      console.log('menuRoutes', menuRoutes)
      for (let item of menuRoutes) {
        router.addRoute('main', item)
      }
      if (this.redirect) {
        router.push(this.redirect)
      } else {
        router.push('/')
        
      }
    },
    async getUserPermissionAction() {
      try {
        let res = await getUserPermissionApi()
        this.userPermissionList = res.data.permission_list
        this.permissiones = getMenuList(this.userPermissionList)
      } catch {}
    }
  }

})
export default useLoginStore