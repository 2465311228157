import request from "../request";
import qs from "query-string";

//获取客户管理列表
export function getuserListApi(params: any) {
  params = qs.stringify(params);
  return request.get(`/admin/user?` + params);
}

//编辑用户列表新增备注
export function editUserRemarkApi(params: any) {
  return request.post("/admin/user", params);
}

