import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { localCache } from "@/utils/cache";
import { ElMessageBox, ElMessage } from "element-plus";
import baseURL from "@/config/base-url";
console.log("baseURL", baseURL);

// 创建 axios 实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 500000,
  headers: { "Content-Type": "application/json;charset=utf-8" },
  withCredentials: true,
});

// 请求拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // const userStore = useUserStoreHook()
    // if (userStore.token) config.headers.Authorization = userStore.token
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code, message } = response.data;
    if (code === "00000" || code === 0) {
      return response.data;
    }
    // 响应数据为二进制流处理(Excel导出)
    if (response.data instanceof ArrayBuffer) {
      return response;
    }
    // 登录失效
    if (code === 101) {
      console.log("router", router.currentRoute.value.fullPath);
      ElMessage.error(message || "系统出错");
      window.location.href = `/#/login?redirect=${localCache.getCache(
        "currentFullPath"
      )}`;
      window.location.reload();
      localCache.clearCache();
      return;
    }
    ElMessage.error(message || "系统出错");
    return Promise.reject(new Error(message || "Error"));
    // return Promise.reject(message || 'Error')
  },
  (error: any) => {
    console.log("error", error);
    if (error.response.data) {
      const { code, message } = error.response.data;
      // token 过期,重新登录
      if (code === 101) {
        ElMessageBox.confirm("当前页面已失效，请重新登录", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        }).then(() => {
          localCache.clearCache();
          window.location.href = `/#/login`;
        });
      } else {
        ElMessage.error(message || "系统出错");
      }
    }
    return Promise.reject(error.message);
  }
);

// 导出 axios 实例
export default service;
