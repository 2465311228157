/** 
  * 通用缓存工具 
  * 支持localStorage 和 sessionStorage
*/
enum cacheType {
  Local,
  Session
}
class Cachestorage {
  storage: Storage
  constructor(type: cacheType) {
    this.storage = type === cacheType.Local ? localStorage : sessionStorage
  }

  setCache(key: string, value: any) {
    if (value) {
      this.storage.setItem(key, JSON.stringify(value))
    }
  }

  getCache(key: string) {
    let value = this.storage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
  }

  removeCache(key: string) {
    this.storage.removeItem(key)
  }

  clearCache() {
    this.storage.clear()
  }
}
const localCache = new Cachestorage(cacheType.Local)
const sessionCache =  new Cachestorage(cacheType.Session)
export {
  localCache,
  sessionCache
}