import { ElLoading } from 'element-plus'
export const svg = `
<svg style="color: #1f42df" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M771.776 794.88A384 384 0 0 1 128 512h64a320 320 0 0 0 555.712 216.448H654.72a32 32 0 1 1 0-64h149.056a32 32 0 0 1 32 32v148.928a32 32 0 1 1-64 0v-50.56zM276.288 295.616h92.992a32 32 0 0 1 0 64H220.16a32 32 0 0 1-32-32V178.56a32 32 0 0 1 64 0v50.56A384 384 0 0 1 896.128 512h-64a320 320 0 0 0-555.776-216.384z"></path></svg>`
export const addLoading = (target: string) => {
  const loadingInstance = ElLoading.service({
    target,
    text: '数据加载中...',
    background: 'rgba(0, 0, 0, .03)',
    spinner:svg
  })
  return loadingInstance
}