import { createApp } from 'vue'
import App from './App.vue'
import store from '@/store'
import router from './router'
import 'animate.css'
import 'element-plus/dist/index.css'
import './style/index.scss'
import Icons from './config/icons'
import TopTitle from './components/top-title/index.vue'

const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(Icons)
  .component('top-title', TopTitle)
  .mount('#app')
