import request from "../request";
import qs from "query-string";

//新增修改案例分类
export function addCaseCategoryApi(params: any) {
  return request.post(`/admin/case_category`, params);
}

//获取案例分类列表
export function getCaseCategoryListApi(params: any) {
  params = qs.stringify(params);
  return request.get(`/admin/case_category?`+params);
}
//删除案例分类
export function deleteCaseCategoryApi(id: number) {
  return request.delete(`/admin/case_category/${id}`);
}
//新增修改话题
export function addCaseTopicApi(params: any) {
  return request.post(`/admin/topic`, params);
}
//获取话题列表
export function getCaseTopicListApi(params: any) {
  params = qs.stringify(params);
  return request.get(`/admin/topic?`+ params);
}
//删除话题
export function deleteCaseTopicApi(id: number) {
  return request.delete(`/admin/topic/${id}`);
}

//案例列表
export function getCaseListApi(params: any) {
  params = qs.stringify(params);
  return request.get(`/admin/case_list?` + params);
}
//新增修改案例
export function addCaseApi(params: any) {
  return request.post(`/admin/case_list`, params);
}
//删除案例
export function deleteCaseApi(id: number) {
  return request.delete(`/admin/case_list/${id}`);
}
//获取案例详情
export function getCaseDetailApi(id: number|string) {
  return request.get(`/admin/case_list/${id}`);
}
//批量上下架
export function batchCaseApi(params: any) {
  return request.post(`/admin/case_list/status_batch`, params);
}
//批量删除
export function batchDeleteCaseApi(params: any) {
  return request.post(`/admin/case_list/destroy_batch`, params);
}